import Axios from "axios";
import { graphql } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Constants } from "../../@types/Constants";
import IParagraph from "../../@types/IParagraph";
import BreadCrumb from "../../components/common/Breadcrumb/Breadcrumb";
import DeepLinking from "../../components/common/DeepLinking/DeepLinking";
import FeaturesTabsCity from "../../components/common/FeaturesTabsCity/FeaturesTabsCity";
import InterestLinks from "../../components/common/IntrestLinks/IntrestLinks";
import ParagraphLayout from "../../components/common/ParagraphLayout/ParagraphLayout";
import Layout from "../../components/global/Layout/Layout";
import SEO from "../../components/global/SEO/SEO";
import Title2 from "../../components/global/Title2/Title2";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useBrandBias from "../../hooks/useBrandBias";
import { useWebFramed } from "../../hooks/useWebFramed";
import { searchSelector, setSearch } from "../../redux/slices/Search/search";
import { guestServiceProvider } from "../../services/ServiceProvider";
import { addPageTypeInGTMDataLayer } from "../../utils/datalayers";
import { extractBrandFromUrl } from "../../utils/helpers";
import { getParagraph } from "../../utils/paragraphHelpers";
import { HotelListing, PopularAttractions, SubTitle2 } from "./CityPageHelpers";
import { ICityPageProps } from "./CityPageProps";
import { useLocationData } from "../../hooks/useLocationData";
import { useRlhsiteData } from "../../hooks/useRlhsiteData";
///import { addKoddiPageTag } from "../../utils/KoddiTagsGTM";

const searchDateFormat = "yyyy-MM-dd";

const CityPage = (props: ICityPageProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const search = useAppSelector(searchSelector);
  const isWebFramed = useWebFramed();
  const city = props.data.locationCity;
  const coordinates = props.pageContext.coordinates;
  const SITE_ID = 31;
  const [points, setPoints] = useState([]);
  const [distance, setDistance] = useState(10);
  const [interestLinks, setInterestLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = props.location;
  const brandsArr = extractBrandFromUrl(location.search);
  const cityBrandCode =
    location.state && location.state.brand && location.state.brand.brand_code;
  const cityName = props.pageContext.name;
  const { allRlhsite } = useRlhsiteData();
  const { locationStates } = useLocationData();
  // Filter the static data based on the alias
  const filteredLocationState = useMemo(() => {
    return locationStates.nodes.find(
      (node) => node.path.alias === props.pageContext.statePath
    );
  }, [locationStates.nodes, props.pageContext.statePath]);

  const hotelSearchFilters = allRlhsite.edges.length
    ? allRlhsite.edges[0].node.field_filter_selected
    : [];
  const hotelReferences =
    city && city?.hotel_reference && city?.hotel_reference.length
      ? city?.hotel_reference.filter((hotel) => hotel?.status === "1")
      : [];
  const title = props.pageContext.metaTitle;
  const description = props.pageContext.metaDescription;
  const countryCode = props.pageContext.countryCode;
  useBrandBias();
  useEffect(() => {
    if (coordinates !== null) {
      const payload = {
        latitude: coordinates?.lat,
        longitude: coordinates?.lon,
        searchString: cityName,
        searchType: "city",
        distance: 50,
        country: countryCode,
        searchTypeGapi: "locality",
        customUrl: location?.pathname || search?.customUrl || "",
        // rooms: [new Room()],
        // checkin: search.checkin,
        // checkout: search.checkout,
        // discount: search.discount,
        // promotionCode: search.promotionCode,
        // groupCode:  search.groupCode
      };

      dispatch(setSearch(payload));
    }
    addPageTypeInGTMDataLayer("search");
  }, []);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    let didCancel = false;
    (async () => {
      if (!didCancel && coordinates?.lat && coordinates?.lon && distance) {
        setIsLoading(true);
        let url = `/location-proximity?coordinates[value]=${distance}`;
        url += `&coordinates[source_configuration][origin][lat]=${coordinates?.lat}&coordinates[source_configuration][origin][lon]=${coordinates?.lon}`;
        url += "&type[]=point_of_interest";
        if (!didCancel) {
          //Use Location Proximity Response
          const guestService = await guestServiceProvider();
          const response = await guestService.getLocationProximity(
            distance,
            coordinates?.lat,
            coordinates?.lon
          );
          if (response.searchLocations.results) {
            const filteredPoints = response.searchLocations.results.filter(
              (location) => location.locationType === "point_of_interest"
            );
            setPoints(filteredPoints);
            setIsLoading(false);
          }
        }
      }
    })();
    return () => {
      didCancel = true;
      source.cancel("Request cancelled for points of interest.");
    };
  }, [distance]);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    let didCancel = false;
    (async () => {
      if (!didCancel) {
        const guestInstance = await guestServiceProvider();
        const apiUrl = `/${location.pathname.replace(/^\/+/, "")}/`;
        const data = await guestInstance.getLocationInterests(apiUrl);
        if (!didCancel) {
          const resData = data?.searchLocationInterests?.interests || [];
          setInterestLinks(resData);
        }
      }
    })();
    return () => {
      didCancel = true;
      source.cancel("Request cancelled for points of interest.");
    };
  }, []);

  const filterSonestaParagraphs = (paragraph: {
    relationships: { field_sites: { machine_name: string } };
  }) => {
    return paragraph?.relationships?.field_sites
      ? paragraph?.relationships.field_sites.machine_name === "sonesta"
        ? true
        : false
      : false;
  };

  const siteParagraphs = city?.relationships.paragraphs?.filter(
    filterSonestaParagraphs
  );
  const paragraphs = siteParagraphs ? siteParagraphs.map(getParagraph) : [];

  const belowSearchSiteParagraphs =
    city?.relationships.paragraphsBelowSearch?.filter(filterSonestaParagraphs);
  const belowSearchParagraphs = belowSearchSiteParagraphs
    ? belowSearchSiteParagraphs.map(getParagraph)
    : [];

  const handleSetDistance = (newDistance: number) => {
    setDistance(newDistance);
  };

  const sonestaFeatures = props.pageContext.features;

  const _pageBanner = paragraphs.filter((p: IParagraph) => {
    return p
      ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER
      : false;
  });
  const pageIntro = paragraphs.filter((p: IParagraph) => {
    return p ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_INTRO : false;
  });

  const hotelReferencesFix = hotelReferences.map((hotel) => ({
    ...hotel,
    rangeInMilesFromOrigin: hotel.rangeInMilesFromOrigin || 0,
  }));

  const hotelReferencesProp = { hotelReferences: hotelReferencesFix };

  return (
    <Layout showMerchandisingBlock={countryCode === "US"}>
      <SEO
        title={title || `Hotels in ${cityName}`}
        noIndex={props.pageContext.noIndex}
        noFollow={props.pageContext.noFollow}
        description={description || ""}
        includeLeaflet
        koddiTitle="CityPage"
      />
      {_pageBanner}
      {!isWebFramed && <ParagraphLayout {...paragraphs} />}
      <HotelListing
        city={city}
        sortBy={cityBrandCode ? "brand" : ""}
        location={props.location}
        hotelSearchFilters={hotelSearchFilters}
        isBannerAndIntroAcailable={
          pageIntro.length > 0 && _pageBanner.length > 0 ? true : false
        }
        brandsInUrl={brandsArr}
        {...hotelReferencesProp}
      />
      <DeepLinking location={location} pageName={"City"} />
      {!isWebFramed && <ParagraphLayout {...belowSearchParagraphs} />}
      {sonestaFeatures !== null && sonestaFeatures.length ? (
        <Container
          className="bg-light pt-4 pb-4 pt-lg-5 pb-lg-5 mb-4 mb-lg-5 position-relative"
          fluid
        >
          <a
            className="anchor-target visually-hidden"
            id="HotelDetails"
            aria-hidden={true}
          />
          <Container fluid="sm" className="p-0">
            <Row>
              <SubTitle2 id={"about-the-city"}>
                {props.pageContext.sectionHeader || "About The City"}
              </SubTitle2>
              <Title2 id={"attractions-location"}>
                {props.pageContext.attractionTabHeader || "Attractions"}
              </Title2>
            </Row>

            <FeaturesTabsCity features={sonestaFeatures} />
          </Container>
        </Container>
      ) : null}
      {!isWebFramed && (
        <>
          {" "}
          <PopularAttractions
            points={points}
            isLoading={isLoading}
            city={city}
            coordinates={coordinates}
            distance={distance}
            setDistance={handleSetDistance}
          />
          <div className={"bg-light pt-4 pb-4 position-relative"}>
            {interestLinks.length > 0 ? (
              <InterestLinks
                InterestLinks={interestLinks}
                cityName={cityName.split(", ")[0]}
              />
            ) : null}
          </div>
          <div className={"bg-light pt-4 pb-4 pb-lg-5 position-relative"}>
            <div className={"container-sm"}>
              <BreadCrumb
                cityName={cityName}
                location={props.location}
                activeTitle=""
                countryCode={countryCode}
                statePath={filteredLocationState}
                cityParentAlias={props.pageContext.fields.path?.parent_alias}
              />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};
{
  /*TODO: Add ParagraphPhotoGallery*/
}
export const query = graphql`
  query ($slug: String!) {
    locationCity(fields: { path: { alias: { eq: $slug } } }) {
      name
      hotel_reference {
        name
        status
        brand {
          code
          logoUrl
          name
        }
        coordinates {
          latitude
          longitude
        }
        address {
          locality
          streetAddress1
          postalCode
          cityName
          country
          region
        }
        crsHotelCode
        crsName
        gallery
        galleryImages {
          alt
          url
        }
        hotelCode
        hotelId
        phone
        rangeInMilesFromOrigin
        url
        amenities
        amenityLabels
        tagline
        tags
        smartScore
        secondaryCity
      }
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphPageBanner
          ...ParagraphPageIntro
          ...ParagraphMediaCta
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphPhotoGallery
          ...ParagraphFrequentlyAskedQuestions
        }
        paragraphsBelowSearch: field_sections_below_search {
          type: __typename
          ...ParagraphPageIntro
          ...ParagraphMediaCta
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphPhotoGallery
          ...ParagraphFrequentlyAskedQuestions
        }
      }
    }
  }
`;

export default CityPage;
